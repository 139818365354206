import './projects.css';
import React from 'react'
import projects_logo from './projects_logo.svg'
import { useState, useEffect } from "react"
import Project from './project_template.jsx'
import projects_data from './projects.json'
import RenderProject from './render_project.jsx'
import PrjNav from './project_nav.jsx'

var navData = {}

projects_data.projects.sort((a,b) => a.displayedId - b.displayedId).map(prj => {
	if (navData[prj.category]) {
		navData[prj.category] = {"ids":[...navData[prj.category].ids, prj.id], "titles":[...navData[prj.category].titles, prj.title]}
	} else {
		navData[prj.category] = {"ids":[prj.id], "titles":[prj.title]}
	}
})

function Projects({ref2}) {
	const prjCount = projects_data.projects.length
	const [idPrj, setIdPrj] = useState(0)
	const [isOpen, setIsOpen] = useState(false)
	const [isNavOpen, setIsNavOpen] = useState(false)
	const selectPrj = (id) => {
		setIdPrj(id);
		console.log(idPrj)
		setIsOpen(true);
	}
	const closePrj = () => {
		setIsOpen(false);
		setIdPrj(0);
	}
	const openPrjNav = () => {
		console.log(navData)
		setIsNavOpen(true)
	}
	const closePrjNav = () => {
		setIsNavOpen(false)
	} 

	const setNexPrjByDisplayId = (displayedId) => {
		const nextPrj = projects_data.projects.find(project => {return project.displayedId === displayedId})
		setIdPrj(nextPrj.id)
	}

	useEffect(() => {
	  document.body.classList.toggle('project-open', isOpen);
	},[isOpen])

	// const [isBackButtonClicked, setBackbuttonPress] = useState(false)

	// useEffect(() => {

	// window.history.pushState(null, null, window.location.pathname);
	// window.addEventListener('popstate', onBackButtonEvent);

	// //logic for showing popup warning on page refresh
	// window.onbeforeunload = function () {

	//   return "Data will be lost if you leave the page, are you sure?";
	// };
	// return () => {
	//   window.removeEventListener('popstate', onBackButtonEvent);
	// }

	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	// const onBackButtonEvent = (e) => {
	// e.preventDefault();
	// if (!isBackButtonClicked && idPrj == 0) {

	//   if (window.confirm("Do you want to go to Test Listing")) {
	//     setBackbuttonPress(true)
	//   } else {
	//     window.history.pushState(null, null, window.location.pathname);
	//     setBackbuttonPress(false)
	//     setIsOpen(false)
	//   }
	// }
	// }

	// {data.content.body.map(block => block.component)}



	return (
		<>
		<section ref={ref2} id="projects" className="projects-section">
			<div className="projects-header">
				{!isNavOpen && <img className="projects-logo" src={projects_logo} onClick={openPrjNav}/>}
			</div>
			<div className="projects-container">
				{projects_data.projects.sort((a,b) => a.displayedId - b.displayedId).map((project, i) => <RenderProject displayId={i+1} config={project} selectPrj={selectPrj} />)}
{/*				<div className="project-wrapper">
		  			<div className="project-title">
		  				<h1>01</h1>
		  				<div className="description-container">
		  					<p>web design/</p>
		  					<p>title/</p>
		  					<p>desktop</p>
		  				</div>
		  				
		  			</div>
		  			<div className="project-image-container" onClick={() => selectPrj(1)}>
		  			 	<div className="img-overlay"><span className="img-overlay-text">see more</span></div>
							
		  			</div>
	  			</div>*/}
			</div>
		</section>
					{isOpen &&
		<Project prjID={idPrj} setIdPrj={setNexPrjByDisplayId} prjCount={prjCount} closeFun={closePrj} data={projects_data.projects.find(project =>{return project.id === idPrj})}/>
		}
		{ isNavOpen &&
		<PrjNav closeFun={closePrjNav} selectPrj={selectPrj} navData={navData}/>
		}
		</>
	);
}

export default React.forwardRef(Projects);