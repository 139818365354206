import React from "react";
import useImageLoaded from './useImageLoaded.js'
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import ProjectPreview from './project_preview.jsx'
import { CheckCircle2, XCircle } from "lucide-react";

function Icon({className, type}) {
  console.log(type)
  switch(type) {
    case "cross":
      return (<XCircle className={className} strokeWidth={1} size={48}/>)
    case "tick":
      return (<CheckCircle2 fill="#6238EB" className={className} strokeWidth={1} size={48}/>)
  }
}

function RenderPhotos({photo, prjID}) {
  const [showPreview, setShowPreview] = useState(false)
  const [posTop, setPosTop] = useState(0)
  const [posBottom, setPosBottom] = useState(0)
  const [posRight, setPosRight] = useState(0)
  const [clickY, setClickY] = useState(0)
  const [imgH, setImgH] = useState(0)


  const openPreview = () => {
    setShowPreview(true);
  }
  const closePreview = () => {
    setTimeout(() => {setShowPreview(false)},500);
  }

  const onClick = event => {
    const rect = event.target.getBoundingClientRect();
    setPosTop(rect.top)
    setPosBottom(rect.bottom)
    setPosRight(rect.right)
    setClickY(event.clientY)
    setImgH(event.target.clientHeight)
    setShowPreview(true);
  }

  const [ref, loaded, onLoad] = useImageLoaded();
  return (
    <div className={"photo-content " + (photo.highlight ? "photo-highlight ":"") +"img-"+photo.size+"-"+photo.orientation}>
      {"heading" in photo && photo.heading !== "" &&
        <h1 className="photo-heading">{photo.heading}</h1>
      }
      {"description" in photo && 
      <>
        {/* <div className="photo-description">
          {photo.description.map(item => <p>{item.paragraph}</p>)}
        </div> */}
        <div className="photo-description">
          {photo.description.map(item => 
          ("paragraph" in item &&
            <>
              {"avatar" in item && <img className="avatar" ref={ref} onLoad={onLoad} src={"./assets/"+prjID+"/"+item.avatar} />}
              <h2>{item.subtitle}</h2>
              <p>{item.paragraph}</p>
            </> 
          ) ||
          ("list" in item &&
            <>
              <p style={{fontWeight:"bold"}}>{item.list.title}</p>
              <ul className="list-container">
                {item.list.elements.map(element => <div className="list-item"><li>{element.text}</li>{<Icon className={"list-icon"} type={element.icon}/>}</div>)}
              </ul>
            </>
          )
          )}
        </div>
      </>
      }
      {/* {"list" in photo && photo.description.list.title !== "" && 
        <div className="photo-description">
          <p>{photo.list.title}</p>
          <ul className="list-container">
            {photo.list.elements.map(element => <div className="list-item"><li>{element.text}</li>{<Icon className={"list-icon"} type={element.icon}/>}</div>)}
          </ul>
        </div>
      } */}

{/* className={"img-"+photo.size+"-"+photo.orientation} */}
      {"url" in photo &&
        <div><img key={photo.url} onClick={photo.preview ? onClick : undefined} className={"project-photo "+(loaded ? "show" : "hide")} ref={ref} onLoad={onLoad} src={"./assets/"+prjID+"/"+photo.url} />
        </div>
      }
      {showPreview && <ProjectPreview posTop={posTop} posBottom={posBottom} posRight={posRight} clickY={clickY} imgH={imgH} photo={photo} prjID={prjID} closePreview={closePreview}/>}
    </div>
    )
}

function isShort(input) {
  var string = String(input)
  if (string.length < 80) {
    return true
  }

  return false
}

function RenderContent({category, prjID}) {
  //const [ref, loaded, onLoad] = useImageLoaded();
  // const [show, setShow] = useState(false);
  // const [inView] = useInView();

  // useEffect(() => {
  //   if (inView && loaded) {
  //     setShow(true);
  //   } else {
  //     setShow(false);
  //   }
  // }, [inView]);
  return (
      <div className="project-content">
        <div className="content-header">
          <img className="web-logo" src={"./assets/"+category.key+"_logo.svg"} />
        </div>
        <div className="content-container">
          {("description" in category && category.description !== "") && 
          <div className={"category-description"+(isShort(category.description) ? " short" : "")}>
            <p>{category.description}</p>
          </div>}
          {category.photos.map(photo => <RenderPhotos photo={photo} prjID={prjID}/>)}
          
{/*          <img src={p1w2sh} />
          <div className="img-big">
            <img src={p1w3bv} />
          </div>*/}
          {"link" in category && <div className="project-link">
            <a href={category.link} target="_blank">{category.link_name}</a>
          </div>}
        </div>
      </div>
    )
}

export default RenderContent;