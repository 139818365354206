import './contact.css';
import React from 'react'
import contact from './contact_footer.svg'
import contact_mobile from './contact_footer_mobile.svg'
import rectangle from './rectangle_contact.svg'
import useMobile from './is_use_mobile.js'


function Contact({ref3}) {

const isMobile = useMobile()

  return (
  	<section ref={ref3} id="contact" className="contact-section">
  		<div className="social-media-wrapper">
  			<img className="contact-logo" src={rectangle} />
  			<div className="sm-links">
  				<a href="https://instagram.com/olemazole" target="_blank">instagram</a>
  				<a href="https://www.linkedin.com/in/aleksandra-mazur-1608a118b/" target="_blank">linkedin</a>
  				<a href="mailto:olemazole@gmail.com" target="_blank">olemazole@gmail.com</a>
  			</div>
  		</div>
  		<div className="contact-text-wrapper">
  			<img className="contact-text-footer" src={isMobile ? contact_mobile : contact} />
  		</div>
  	</section>
  );
}

export default React.forwardRef(Contact);