import './project_nav.css';
import { useEffect, useState } from "react";
import projects_logo from './projects_logo.svg'

function PrjNav({closeFun, selectPrj, navData}) {

	useEffect(() => {
	const handleEsc = (event) => {
	   if (event.key === 'Escape') {
	    gracefulClose()
	  }
	};
	window.addEventListener('keydown', handleEsc);
	document.body.classList.toggle('project-open', true);

	return () => {
	  window.removeEventListener('keydown', handleEsc);
	  document.body.classList.toggle('project-open', false);
	};
	}, []);

	const [currentClass, setCurrentClass] = useState({
		logo: '', overlay:'', category:'', items:'',
	})
	const gracefulClose = () => {
		Object.entries(navData).map(([key, value]) => {
			console.log(key,value)
		})
		console.log(navData)
		setCurrentClass({logo:'closeAnimation', overlay:'overlay-out', category:'category-out', items:'category-items-out'})
		setTimeout(() => {closeFun()},1000);
	}

	return (
		<div onClick={gracefulClose} className={"prj-nav-overlay "+currentClass.overlay}>
			<img className={"projects-logo overlay-logo "+currentClass.logo} onClick={gracefulClose} src={projects_logo} />
			<div className="categories-wrapper">
				{/* <div className="category">
					<span className={"category-span "+currentClass.category}>Web design</span>
					<div className={"category-items "+currentClass.items}>
						<p onClick={()=>selectPrj(1)} style={{animationDelay: "0ms"}}>Project 1</p>
						<p style={{animationDelay: "250ms"}}>Project 2</p>
						<p style={{animationDelay: "500ms"}}>Project 3</p>
						<p style={{animationDelay: "750ms"}}>Project 4</p>
					</div>
				</div> */}
				{Object.entries(navData).map(([key, value]) => (
					<div className="category">
						<span className={"category-span "+currentClass.category}><h1>{key}</h1></span>
						<div className={"category-items "+currentClass.items}>
							{value.ids.map((val, i) => <div className='project-nav-item' style={{animationDelay: `${i*250}ms`}} onClick={()=>selectPrj(val)}><h2>{`0${i+1}`}</h2><p>{navData[key].titles[i]}</p></div>)}
						</div>
					</div>	
				))}
			</div>
		</div>
	);
}

export default PrjNav;
