import React from 'react'
import burger from './burger.svg';
import title from './olemazole.svg'
import title_mobile from './olemazole_mobile.svg'
import arrow from './arrow.svg'
import contact from './contact.svg'
import './navbar.css';
import './nav_overlay.css'
import { useEffect, useRef, useState, useMemo } from "react"
import useMobile from './is_use_mobile.js'


function Navbar({ref1, refAbout, refProjects, refContact, isNavMainColor, isContactVisible}) {
	const [isNavExpanded, setIsNavExpanded] = useState(false)
	const toggleMenu = () => setIsNavExpanded(!isNavExpanded)
	const isMobile = useMobile()

	const scrollToAbout = () => refAbout.current.scrollIntoView()
	const scrollToProjects = () => refProjects.current.scrollIntoView()
	const scrollToContact = () => refContact.current.scrollIntoView()

	useEffect(() => {
	  document.body.classList.toggle('project-open', isNavExpanded);
	},[isNavExpanded])

	useEffect(() => {
	const handleEsc = (event) => {
	   if (event.key === 'Escape') {
	    setIsNavExpanded(false)
	  }
	};
	window.addEventListener('keydown', handleEsc);

	return () => {
	  window.removeEventListener('keydown', handleEsc);
	};
	}, []);

	return (
		<>
		<div className="navbar">
			<svg onClick={toggleMenu} className={"burger-nav "+(isNavMainColor ? "main" : "")} width="67" height="43" viewBox="0 0 67 43" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 10H67" stroke="currentColor" stroke-width="12"/>
				<path d="M0 34H67" stroke="currentColor" stroke-width="12"/>
			</svg>

			<div className={isNavExpanded ? "overlay expanded" : "overlay"}>
			<a className="closebtn" onClick={toggleMenu}>
				<svg width="55" height="55" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3.00391 38.3555L38.3592 3.00013" stroke="currentColor" stroke-width="12"/>
					<path d="M38.3555 38.3555L3.00013 3.00013" stroke="currentColor" stroke-width="12"/>
				</svg>

			</a>
			  <div className="overlay-content">
			    <a onClick={()=> (toggleMenu(), scrollToAbout()) }>about</a>
			    <a onClick={()=> (toggleMenu(), scrollToProjects()) }>projects</a>
			    <a onClick={()=> (toggleMenu(), scrollToContact()) }>contact</a>
			  </div>
	     	</div>
			{isContactVisible && <div className="contact-img">
				<a onClick={scrollToContact}>
					<svg className={"contact-svg "+(isNavMainColor ? "main" : "")} width="250" height="41" viewBox="0 0 250 41" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M15.809 40.845C12.8563 40.845 10.1702 40.2298 7.7507 38.9996C5.37218 37.7693 3.48576 35.9854 2.09146 33.6479C0.697153 31.3104 0 28.4807 0 25.159L0 24.2978C0 20.9761 0.697153 18.1465 2.09146 15.809C3.48576 13.4715 5.37218 11.6876 7.7507 10.4573C10.1702 9.22702 12.8563 8.61189 15.809 8.61189C18.7206 8.61189 21.2222 9.1245 23.3136 10.1497C25.4051 11.175 27.0864 12.5898 28.3577 14.3942C29.67 16.1575 30.5312 18.167 30.9413 20.4225L23.4366 22.0218C23.2726 20.7916 22.9035 19.6843 22.3294 18.7001C21.7553 17.7159 20.9351 16.9367 19.8689 16.3626C18.8436 15.7885 17.5518 15.5014 15.9935 15.5014C14.4352 15.5014 13.0204 15.85 11.7491 16.5471C10.5188 17.2033 9.53459 18.208 8.79643 19.5613C8.09928 20.8736 7.7507 22.4934 7.7507 24.4209V25.036C7.7507 26.9634 8.09928 28.6038 8.79643 29.9571C9.53459 31.2694 10.5188 32.2741 11.7491 32.9712C13.0204 33.6274 14.4352 33.9554 15.9935 33.9554C18.331 33.9554 20.0944 33.3608 21.2837 32.1716C22.5139 30.9413 23.2931 29.3419 23.6212 27.3735L31.1258 29.1574C30.5927 31.3309 29.67 33.3198 28.3577 35.1242C27.0864 36.8876 25.4051 38.2819 23.3136 39.3071C21.2222 40.3323 18.7206 40.845 15.809 40.845Z" fill="currentColor"/>
					<path d="M52.0366 40.845C49.0019 40.845 46.2748 40.2298 43.8553 38.9996C41.4357 37.7693 39.5288 35.9854 38.1345 33.6479C36.7402 31.3104 36.0431 28.5012 36.0431 25.2205V24.2363C36.0431 20.9556 36.7402 18.1465 38.1345 15.809C39.5288 13.4715 41.4357 11.6876 43.8553 10.4573C46.2748 9.22702 49.0019 8.61189 52.0366 8.61189C55.0712 8.61189 57.7983 9.22702 60.2179 10.4573C62.6374 11.6876 64.5443 13.4715 65.9386 15.809C67.3329 18.1465 68.0301 20.9556 68.0301 24.2363V25.2205C68.0301 28.5012 67.3329 31.3104 65.9386 33.6479C64.5443 35.9854 62.6374 37.7693 60.2179 38.9996C57.7983 40.2298 55.0712 40.845 52.0366 40.845ZM52.0366 33.9554C54.4151 33.9554 56.3835 33.1968 57.9419 31.6794C59.5002 30.1211 60.2794 27.9066 60.2794 25.036V24.4209C60.2794 21.5502 59.5002 19.3562 57.9419 17.8389C56.4245 16.2806 54.4561 15.5014 52.0366 15.5014C49.658 15.5014 47.6896 16.2806 46.1313 17.8389C44.5729 19.3562 43.7938 21.5502 43.7938 24.4209V25.036C43.7938 27.9066 44.5729 30.1211 46.1313 31.6794C47.6896 33.1968 49.658 33.9554 52.0366 33.9554Z" fill="currentColor"/>
					<path d="M75.1844 39.9838V9.47308L82.812 9.47308V13.4715H83.9193C84.4114 12.4052 85.3341 11.4005 86.6874 10.4573C88.0407 9.47308 90.0911 8.98097 92.8387 8.98097C95.2173 8.98097 97.2882 9.53459 99.0516 10.6418C100.856 11.7081 102.25 13.2049 103.235 15.1323C104.219 17.0187 104.711 19.2332 104.711 21.7758V39.9838H96.9602V22.3909C96.9602 20.0944 96.386 18.372 95.2378 17.2238C94.1305 16.0755 92.5312 15.5014 90.4397 15.5014C88.0612 15.5014 86.2158 16.3011 84.9035 17.9004C83.5912 19.4588 82.9351 21.6527 82.9351 24.4824V39.9838H75.1844Z" fill="currentColor"/>
					<path d="M123.936 39.9838C121.927 39.9838 120.286 39.3686 119.015 38.1384C117.785 36.8671 117.17 35.1857 117.17 33.0943V15.8705H109.542V9.47308H117.17V0L124.92 0V9.47308H133.286V15.8705H124.92V31.741C124.92 32.9712 125.495 33.5864 126.643 33.5864H132.548V39.9838H123.936Z" fill="currentColor"/>
					<path d="M149.775 40.845C147.602 40.845 145.654 40.4759 143.931 39.7377C142.209 38.9585 140.835 37.8513 139.81 36.416C138.826 34.9397 138.334 33.1558 138.334 31.0643C138.334 28.9729 138.826 27.23 139.81 25.8357C140.835 24.4004 142.23 23.3341 143.993 22.637C145.797 21.8988 147.848 21.5297 150.144 21.5297H158.51V19.8073C158.51 18.372 158.059 17.2033 157.157 16.3011C156.255 15.3579 154.819 14.8863 152.851 14.8863C150.923 14.8863 149.488 15.3374 148.545 16.2396C147.602 17.1007 146.987 18.2285 146.7 19.6228L139.564 17.2238C140.056 15.6654 140.835 14.2506 141.902 12.9793C143.009 11.6671 144.465 10.6213 146.269 9.84216C148.114 9.02198 150.349 8.61189 152.974 8.61189C156.993 8.61189 160.171 9.61661 162.509 11.626C164.846 13.6355 166.015 16.5471 166.015 20.361V31.741C166.015 32.9712 166.589 33.5864 167.737 33.5864H170.198V39.9838H165.031C163.513 39.9838 162.262 39.6147 161.278 38.8765C160.294 38.1384 159.802 37.1541 159.802 35.9239V35.8624H158.633C158.469 36.3545 158.1 37.0106 157.526 37.8308C156.952 38.61 156.05 39.3071 154.819 39.9223C153.589 40.5374 151.908 40.845 149.775 40.845ZM151.129 34.5706C153.302 34.5706 155.065 33.9759 156.419 32.7867C157.813 31.5564 158.51 29.9366 158.51 27.9271V27.312H150.698C149.263 27.312 148.135 27.6196 147.315 28.2347C146.495 28.8498 146.084 29.711 146.084 30.8183C146.084 31.9255 146.515 32.8277 147.376 33.5249C148.237 34.222 149.488 34.5706 151.129 34.5706Z" fill="currentColor"/>
					<path d="M189.597 40.845C186.644 40.845 183.958 40.2298 181.538 38.9996C179.16 37.7693 177.273 35.9854 175.879 33.6479C174.485 31.3104 173.788 28.4807 173.788 25.159V24.2978C173.788 20.9761 174.485 18.1465 175.879 15.809C177.273 13.4715 179.16 11.6876 181.538 10.4573C183.958 9.22702 186.644 8.61189 189.597 8.61189C192.508 8.61189 195.01 9.1245 197.101 10.1497C199.193 11.175 200.874 12.5898 202.145 14.3942C203.458 16.1575 204.319 18.167 204.729 20.4225L197.224 22.0218C197.06 20.7916 196.691 19.6843 196.117 18.7001C195.543 17.7159 194.723 16.9367 193.656 16.3626C192.631 15.7885 191.339 15.5014 189.781 15.5014C188.223 15.5014 186.808 15.85 185.537 16.5471C184.306 17.2033 183.322 18.208 182.584 19.5613C181.887 20.8736 181.538 22.4934 181.538 24.4209V25.036C181.538 26.9634 181.887 28.6038 182.584 29.9571C183.322 31.2694 184.306 32.2741 185.537 32.9712C186.808 33.6274 188.223 33.9554 189.781 33.9554C192.119 33.9554 193.882 33.3608 195.071 32.1716C196.302 30.9413 197.081 29.3419 197.409 27.3735L204.913 29.1574C204.38 31.3309 203.458 33.3198 202.145 35.1242C200.874 36.8876 199.193 38.2819 197.101 39.3071C195.01 40.3323 192.508 40.845 189.597 40.845Z" fill="currentColor"/>
					<path d="M243.199 40.845C241.558 40.845 240.164 40.3118 239.016 39.2456C237.909 38.1384 237.355 36.7236 237.355 35.0012C237.355 33.2788 237.909 31.8845 239.016 30.8183C240.164 29.711 241.558 29.1574 243.199 29.1574C244.88 29.1574 246.275 29.711 247.382 30.8183C248.489 31.8845 249.043 33.2788 249.043 35.0012C249.043 36.7236 248.489 38.1384 247.382 39.2456C246.275 40.3118 244.88 40.845 243.199 40.845Z" fill="currentColor"/>
					<path d="M220.615 39.9838C222.624 39.9838 224.264 39.3686 225.536 38.1384C226.766 36.8671 227.381 35.1857 227.381 33.0943V15.8705L235.009 15.8705V9.47308L227.381 9.47308V0L219.63 0V9.47308L211.265 9.47308V15.8705L219.63 15.8705V31.741C219.63 32.9712 219.056 33.5864 217.908 33.5864H212.003V39.9838H220.615Z" fill="currentColor"/>
					</svg>
				</a>
			</div>}
		</div>
		<div ref={ref1} class="scroll">
			<div class="m-scroll">
				{isMobile ? <div onClick={scrollToAbout} className='rounded-rect'></div> : 
					<div className="olemazole">
						<div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
						<div class="flip-card-hero">
							<div class="flip-card-inner">
							<div class="flip-card-front">

							</div>
							<div class="flip-card-back">
								<span className='ole'>ole</span>          
							</div>
							</div>
						</div>
						<span style={{height:"23vw"}}>mazole</span>
						</div>
					</div>
				}
			</div>
			{!isMobile && <div className="about-link">
				<a href="#about">
				<svg className='arrow' width="64" height="68" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M31.7852 0L31.7852 57.1583" stroke="currentColor" stroke-width="12"/>
					<path d="M5 28.8115L30.286 60.3819C31.0901 61.3858 32.6191 61.3807 33.4164 60.3714L58.3477 28.8115" stroke="currentColor" stroke-width="12"/>
				</svg>

				</a>
				{/* <div className='rounded-rect'></div> */}
			</div>}
		</div>

{/*		<div className="nav-header">
			<div className="title-div">
				<div className="about-link">
					<a href="#about">
						<svg className="arrow" width="98" height="103" viewBox="0 0 98 103" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M49 0V93.8893" stroke="currentColor" stroke-width="12.66"/>
						<path d="M5 47.3262L41.2581 92.5956C45.2785 97.6152 52.9235 97.5895 56.9101 92.543L92.63 47.3262" stroke="currentColor" stroke-width="12.66"/>
						</svg>
					</a>
				</div>
				<img src={isMobile ? title_mobile : title} className="title"/>
			</div>
		</div>*/}
		</>
	);
}

export default React.forwardRef(Navbar);
