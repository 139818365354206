import React from 'react'
import logo from './logo.svg';
import './App.css';
import Navbar from './components/nav.js'
import About from './components/about.js'
import Divider from './components/divider.jsx'
import Projects from './components/projects.jsx'
import Contact from './components/contact.jsx'
import { useEffect, useRef, useState, useMemo } from "react"


function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

function App() {
  const ref1 = useRef(null);
  const isInViewport1 = useIsInViewport(ref1);
  const refProjects = useRef(null);
  const isInViewport2 = useIsInViewport(refProjects);
  const refContact = useRef(null);
  const isInViewport3 = useIsInViewport(refContact);
  const refAbout = useRef(null);
  const [isNavMainColor, setIsNavMainColor] = useState(false)
  const [isContactVisible, setIsContactVisible] = useState(false)

  useEffect(() =>{
    setIsNavMainColor(isInViewport1 || (!isInViewport2 && isInViewport3))
    setIsContactVisible(isInViewport2 || !isInViewport3)
  },[isInViewport1,isInViewport2,isInViewport3])

  return (
  <div className="wrapper">
    <Navbar ref1={ref1} refAbout={refAbout} refProjects={refProjects} refContact={refContact} isNavMainColor={isNavMainColor} isContactVisible={isContactVisible}/>
    <About refAbout={refAbout}/>
    <Divider />
    <Projects ref2={refProjects}/>
    <Contact ref3={refContact}/>
   </div>
  ); 
}

export default App;
