import './about.css';
import about_logo from './about_logo.svg'
import about from './about.svg'
import about_mobile from './about_mobile.svg'
import photo_ola from './photo_ola.png'
import useMobile from './is_use_mobile.js'

function AboutDesktop ({refAbout}) {
  return (
      <section ref={refAbout} id="about" className="about-section">
        <div className="about-logo-wrapper">
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">

              </div>
              <div class="flip-card-back">
                <img src={photo_ola} />             
              </div>
            </div>
          </div>
        </div>
        <div className="about-wrapper">
        <div className="text-wrapper">
          <p className="about-text">I'm an enthusiastic, creative Designer with four years of experience. My work ethic is characterized by self-motivation, reliability and solid problem solving skills, which I have honed through various permanent and casual employment. 
            <br></br><br></br>
           
I take immense pride in my work and actively seek out opportunities to refine my skills. Exploring and learning new things is a key for me to stay visible in the Design Industry.

          </p>
        </div>
          <img className="about" src={about} />
        </div>
      </section>
    );
}
function AboutMobile ({refAbout}) {
  return (
      <section ref={refAbout} id="about" className="about-section">
        <div className="text-wrapper">
          <p className="about-text">I'm an enthusiastic, creative Designer with four years of experience. My work ethic is characterized by self-motivation, reliability, and solid problem solving skills, which I have honed through various permanent and casual employment. 
            <br></br><br></br>
           
I take immense pride in my work and actively seek out opportunities to refine my skills. Exploring and learning new things ia a key for me to stay visible in the Design Industry.

          </p>
        </div>
        <div className="about-logo-wrapper">
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img className="rectangle" src={about_logo} />
                <div className="about-wrapper">
                  <img className="about" src={about_mobile} />
                </div>
              </div>
              <div class="flip-card-back">
                <img src={photo_ola} />             
              </div>
            </div>
          </div>
        </div>

      </section>
    );
}

function About({refAbout}) {
  const isMobile = useMobile()
  return isMobile ? <AboutMobile refAbout={refAbout}/> : <AboutDesktop refAbout={refAbout}/>;
}

export default About;
