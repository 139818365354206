import './divider.css';
import divider from './divider.svg'
import divider_mobile from './divider_mobile.svg'
import useMobile from './is_use_mobile.js'


function Divider() {
	const isMobile = useMobile()
	return (
	  <section className="divider-section">
	    <div className="divider-wrapper">
	      <img className="divider-text" src={isMobile ? divider_mobile : divider} alt=""/>
	    </div>
	  </section>
	);
}

export default Divider;
