import React from "react";

function RenderProject({config, displayId, selectPrj}) {
  return (
        <div key={displayId} className="project-wrapper">
            <div className="project-title">
              <h1>{displayId < 10 ? "0":""}{displayId}</h1>
              <div className="description-container">
                <p>{config.category}</p>
                <p>{config.title}</p>
                <p>{config.type}</p>
              </div>
              
            </div>
            <div style={{background: `url(./assets/${config.id}/${config.cover_photo}) no-repeat`, backgroundPosition: 'center', backgroundSize: 'cover'}} className="project-image-container" onClick={() => selectPrj(config.id)}>
              <div className="img-overlay"><span className="img-overlay-text">see more</span></div>
              
            </div>
          </div>
    )
}

export default RenderProject;