import './project_preview.css'
import useImageLoaded from './useImageLoaded.js'
import {useEffect, useState} from "react"
import useMobile from './is_use_mobile.js'

function ProjectPreview({photo, prjID, closePreview, posTop, posBottom, posRight, imgH, clickY}) {
	const [ref, loaded, onLoad] = useImageLoaded();
	const [show, setShow] = useState(true)
	const isMobile = useMobile()
	const scaler = isMobile ? 1.8 : 3.3
	const myStyle = {
		paddingTop: posTop,
		paddingBottom: window.innerHeight-posBottom,
		paddingRight: window.innerWidth-posRight
	};

	
	useEffect(() => {

		const photo = document.querySelector('#preview-photo')
		const el = document.querySelector('#preview')
		const posRatio = ((-posTop)+clickY)/imgH
		console.log(posRatio)
		console.log(photo.clientHeight)
		const scrollPos = posRatio * photo.clientHeight * scaler -0.5*window.innerHeight
		console.log(scrollPos) 
		setTimeout(() => {el.scrollTo({top:scrollPos, behavior: 'smooth'})}, 600)
	}, [])

	return (
      <div id="preview" onClick={() => {closePreview();setShow(false);const el = document.querySelector('#preview');el.scrollTo(0,-posTop)}} className={"project-preview"}><img id="preview-photo" key={photo.url} style={!loaded || !show ? myStyle : undefined} className={"project-photo "+(loaded && show ? "show" : "hide-preview")} ref={ref} onLoad={onLoad} src={"./assets/"+prjID+"/"+photo.url} />
      </div>
		)

}

export default ProjectPreview;