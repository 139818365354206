import './project_template.css'
import return_arrow from './return.svg'
import web_logo from './web_design_logo.svg'
import kv_logo from './kv_logo.svg'
import RenderContent from './render_content.jsx'
import useImageLoaded from './useImageLoaded.js'
import { useState, useRef, useEffect } from "react";

function Project({prjID, setIdPrj, prjCount, closeFun, data}) {
	const [ref, loaded, onLoad] = useImageLoaded();
	const [touchStart, setTouchStart] = useState(0);
	const [touchEnd, setTouchEnd] = useState(0);
	const myRef = useRef(null)

	useEffect(() => {
	const handleEsc = (event) => {
	   if (event.key === 'Escape') {
	    closeFun()
	  }
	};
	window.addEventListener('keydown', handleEsc);

	return () => {
	  window.removeEventListener('keydown', handleEsc);
	};
	}, []);

	const executeScroll = () => myRef.current.scrollIntoView()  

	function nextPrj () {
	    if (data.displayedId < prjCount) {
	    	setIdPrj(data.displayedId+1);
	    	executeScroll();	        	
	    }
	}

	function previousPrj () {
        if (data.displayedId > 1) {
        	setIdPrj(data.displayedId-1);
        	executeScroll();	        	
        }
	}

	function handleTouchStart(e) {
	    setTouchStart(e.targetTouches[0].clientX);
	}

	function handleTouchMove(e) {
	    setTouchEnd(e.targetTouches[0].clientX);
	}

	function handleTouchEnd() {
	    if (touchStart - touchEnd > 150 && touchEnd > 0) {
	    	nextPrj();
	    }

	    if (touchStart - touchEnd < -150 && touchEnd > 0) {
	    	previousPrj();
	    }
	}
	return (
		<section id={prjID} className="container">
			<div ref={myRef} className="project-header">
				<div className="project-nav top">
					<a className="close-button" onClick={closeFun}>
					<svg width="55" height="55" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3.00391 38.3555L38.3592 3.00013" stroke="currentColor" stroke-width="12"/>
						<path d="M38.3555 38.3555L3.00013 3.00013" stroke="currentColor" stroke-width="12"/>
					</svg>

					</a>
				</div>
	  			<div className="project-details">
	  				<h1>{data.displayedId < 10 ? "0":""}{data.displayedId}</h1>
	  				<div className="description-container">
	  					<p>{data.category}</p>
	  					<p>{data.title}</p>
	  					<p>{data.type}</p>
	  				</div>
				</div>
			</div>
			<img className={"main-img"+(loaded ? " show" : " hide")} ref={ref} onLoad={onLoad} src={"./assets/"+prjID+"/"+data.project_photo_config.main} alt="opera"/>
			{data.project_photo_config.categories.map(category => <RenderContent category={category} prjID={prjID}/>)}
{/*			<div className="project-content">
				<div className="content-header">
					<img className="web-logo" src={web_logo} />
				</div>
				<div className="content-container">
					<img src={p1w1sh} />
					<img src={p1w2sh} />
					<div className="img-big">
						<img src={p1w3bv} />
					</div>
				</div>
			</div>
			<div className="project-content">
				<div className="content-header">
					<img className="kv-logo" src={kv_logo} />
				</div>
				<div className="content-container">
					<img src={p1w1sh} />
					<img src={p1w2sh} />
					<div className="img-big">
						<img src={p1w3bv} />
					</div>
				</div>
			</div>*/}
			<div className="project-nav">
				<p onClick={previousPrj}>{(data.displayedId > 1) && "previous"}</p>
				<p onClick={nextPrj}>{(data.displayedId < prjCount) && "next"}</p>
			</div>
		</section>
		)
} 

export default Project;